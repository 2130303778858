import React from 'react';
import styles from './Sending.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Sending = () => {
  return (
    <div className={cx('sending_container')}>
      <div className={cx('sending')}>
        <span>G</span>
        <span>N</span>
        <span>I</span>
        <span>D</span>
        <span>N</span>
        <span>E</span>
        <span>S</span>
      </div>
    </div>
  );
};

export default Sending;
