import React, { useRef, createContext, useEffect, useState } from 'react';
import './Header.scss';
import MenuItem from './components/MenuItem';

const menuList = [
  {
    active: true,
    icon: 'ion-person',
    name: 'About',
    target: '#about',
  },
  {
    icon: 'ion-android-list',
    name: 'Timeline',
    target: '#timeline',
  },
  {
    icon: 'ion-ribbon-b',
    name: 'Rewards',
    target: '#rewards',
  },
  {
    icon: 'ion-paintbrush',
    name: 'Projects',
    target: '#project',
  },
  {
    icon: 'ion-at',
    name: 'Contact',
    target: '#contact',
  },
];

const Header = () => {
  function debounce(fn, ms) {
    let timer;
    return function () {
      const args = arguments;
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, ms);
    };
  }

  const elmTopMenu = useRef(),
    elmMenuList = useRef(),
    [width, setWidth] = useState(window.innerWidth),
    [isTablet, setIsTablet] = useState(width >= 768 && width <= 983),
    [isMobile, setIsMobile] = useState(width <= 767);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setIsMobile(width <= 767);
    setIsTablet(width >= 768 && width <= 983);
  }
  useEffect(() => {
    window.addEventListener('resize', debounce(handleWindowSizeChange, 100));
    if (isTablet || isMobile) {
      let innerElm = [...document.querySelectorAll('.card__inner')],
        menuItem = [...elmMenuList.current.querySelectorAll('li')];
      window.addEventListener(
        'touchmove',
        debounce((e) => {
          innerElm.map((inner, index) => {
            let innerPos = inner.getBoundingClientRect();
            if (innerPos.top >= 0 && innerPos.bottom - window.innerHeight / 5 <= window.innerHeight) {
              menuItem.map((menu) => menu.classList.remove('active'));
              menuItem[index].classList.add('active');
              setPositionFx(elmMenuList.current);
            }
          });
        }, 100),
      );
    }
    return () => {
      window.removeEventListener('resize', () => {});
    };
  });

  const translateMenuFx = (activeMenu, menuFx) => {
    menuFx.style.height = activeMenu.getBoundingClientRect().height + 'px';
    menuFx.style.opacity = 1;
    menuFx.style.top = activeMenu.offsetTop + 'px';
    if (isMobile || isTablet) {
      menuFx.style.left = `${activeMenu.getBoundingClientRect().x}px`;
      menuFx.style.width = `${activeMenu.getBoundingClientRect().width}px`;
    }
  };

  const setPositionFx = (menus) => {
    let activeMenuItem = menus.querySelector('li.active') || menus.querySelector('li:first-child');
    menus.setAttribute('style', `--menu-height: ${activeMenuItem.getBoundingClientRect().height}px; --menu-position: `);
    let menuFx = menus.querySelector('.menu__fx');
    translateMenuFx(activeMenuItem, menuFx);
  };

  useEffect(() => {
    setPositionFx(elmMenuList.current);
  });
  return (
    <HeaderContext.Provider
      value={{
        elmTopMenu,
        elmMenuList,
        setPositionFx,
        isMobile,
        isTablet,
      }}
    >
      <header className="header">
        <div className="top__menu" ref={elmTopMenu}>
          <ul ref={elmMenuList}>
            {menuList.map((menu, index) => (
              <MenuItem
                key={index}
                isActive={menu.active}
                icon={menu.icon}
                menuName={menu.name}
                menuTarget={menu.target}
              />
            ))}
            <li className="menu__fx"></li>
          </ul>
        </div>
      </header>
    </HeaderContext.Provider>
  );
};

export const HeaderContext = createContext();
export default Header;
