import React, { useState, useEffect } from 'react';
import Particles from 'react-tsparticles';
import './scss/App.scss';
import particlesOptions from './particles.json';
import Header from './components/Header';
import CardStarted from './components/CardStarted';
import CardInner from './components/CardInner';
import About from './components/About';
import Timeline from './components/Timeline';
import Project from './components/Project';
import Contact from './components/Contact';
import * as amplitude from '@amplitude/analytics-browser';
import Reward from './components/Reward';

const App = () => {
  const [indexCardStarted, setIndexCardStarted] = useState(2);

  useEffect(
    () =>
      amplitude.init('2e4dddbc9353ab16d7ae50199f11710d', undefined, {
        defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
      }),
    [],
  );
  return (
    <div className="App">
      <Particles options={particlesOptions} />
      <div className="container">
        <Header />
        <CardStarted indexCard={indexCardStarted} />
        <CardInner isActive title="Overview" idName="about">
          <About />
        </CardInner>
        <CardInner title="Timeline" idName="timeline">
          <Timeline />
        </CardInner>
        <CardInner title="Rewards" idName="rewards">
          <Reward />
        </CardInner>
        <CardInner title="Project was implemented" idName="project">
          <Project setIndex={setIndexCardStarted} />
        </CardInner>
        <CardInner title="Contact with me" idName="contact">
          <Contact setIndex={setIndexCardStarted} />
        </CardInner>
      </div>
    </div>
  );
};

export default App;
