import React, { useRef, useEffect, useState } from 'react';
import styles from './Project.module.scss';
import classNames from 'classnames/bind';
import { ref, child, get } from 'firebase/database';

import { database } from '../../firebase';
import ProjectItem from './components/ProjectItem';
import Slider from '../Slider';

const cx = classNames.bind(styles);

const Project = ({ setIndex }) => {
  const matchHeight = (elms, m) => {
    [...elms].map((elm) => (elm.style.height = 'auto'));

    for (let i = 0; i < Math.ceil(elms.length / m); i++) {
      let maxHeight = 0;
      for (let j = 0; j < m; j++) {
        if (elms[i * m + j].getBoundingClientRect().height > maxHeight) {
          maxHeight = elms[i * m + j].getBoundingClientRect().height;
        }
      }
      for (let k = 0; k < m; k++) {
        elms[i * m + k].style.height = maxHeight + 'px';
      }
    }
  };

  const [pjLeft, setPjLeft] = useState([]),
    [pjRight, setPjRight] = useState([]),
    [openModal, setOpenModal] = useState(false),
    [wWidth, setwWidth] = useState(window.innerWidth),
    [wHeight, setwHeight] = useState(window.innerHeight),
    [coverLeft, setCoverLeft] = useState(0),
    [coverTop, setCoverTop] = useState(0),
    [coverWidth, setCoverWidth] = useState(0),
    [coverHeight, setCoverHeight] = useState(0),
    [scaleX, setScaleX] = useState(1),
    [scaleY, setScaleY] = useState(1),
    [offsetX, setOffsetX] = useState(0),
    [offsetY, setOffsetY] = useState(0),
    [opModal, setOpModal] = useState(0),
    [slideList, setSlideList] = useState([]),
    [currentItem, setCurrentItem] = useState({}),
    refSlider = useRef(),
    refInner = useRef();

  const dbRef = ref(database);

  const modalHandleOpen = (id, refCard) => {
    const cardPos = refCard.current.getBoundingClientRect();
    setCoverPosition(cardPos);
    const allPj = [...pjLeft, ...pjRight];
    allPj.map((pj) => {
      if (pj.id == id) {
        if (pj.gallery) setSlideList(pj.gallery);
        else setSlideList([pj.img]);
        setCurrentItem(pj);
      }
    });
    setTimeout(() => {
      setOpModal(1);
      setOpenModal(true);
    }, 300);
  };

  const modalHandleClose = (e) => {
    e.preventDefault();
    setOpenModal(false);
    setOpModal(0);
    setCoverWidth(0);
    setCoverHeight(0);
    setScaleX(1);
    setScaleY(1);
    setOffsetX(0);
    setOffsetY(0);
    setIndex(2);
  };

  const setCoverPosition = (cardPosition) => {
    setIndex(1);
    setCoverLeft(cardPosition.left);
    setCoverTop(cardPosition.top);
    setCoverWidth(cardPosition.width);
    setCoverHeight(cardPosition.height);
    setScaleX(wWidth / cardPosition.width);
    setScaleY(wHeight / cardPosition.height);
    setOffsetX((wWidth / 2 - cardPosition.width / 2 - cardPosition.left) / (wWidth / cardPosition.width));
    setOffsetY((wHeight / 2 - cardPosition.height / 2 - cardPosition.top) / (wHeight / cardPosition.height));
  };

  useEffect(() => {
    get(child(dbRef, `projects`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          let projects = snapshot.val();
          if (projects.length > 1) {
            let midIndex = Math.ceil(projects.length / 2);
            setPjLeft(projects.splice(0, midIndex));
            setPjRight(projects.splice(-midIndex));
          } else setPjLeft(projects);
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className={cx('card__container')}>
        {pjLeft && (
          <div className={cx('card__column')}>
            {pjLeft.map((item, index) => (
              <ProjectItem
                key={index}
                img={item.img}
                name={item.name}
                idProject={item.id}
                modalHandleOpen={modalHandleOpen}
              />
            ))}
          </div>
        )}
        {pjRight && (
          <div className={cx('card__column', 'right')}>
            {pjRight.map((item, index) => (
              <ProjectItem
                key={index}
                img={item.img}
                name={item.name}
                idProject={item.id}
                modalHandleOpen={modalHandleOpen}
              />
            ))}
          </div>
        )}
      </div>
      <div
        className={cx('cover')}
        style={{
          '--left': `${coverLeft}px`,
          '--top': `${coverTop}px`,
          '--width': `${coverWidth}px`,
          '--height': `${coverHeight}px`,
          '--sX': scaleX,
          '--sY': scaleY,
          '--oX': `${offsetX}px`,
          '--oY': `${offsetY}px`,
        }}
      ></div>
      {openModal && (
        <div
          className={cx('modal')}
          style={{
            '--opModal': opModal,
          }}
        >
          <a href="#" className={cx('close__content')} onClick={(e) => modalHandleClose(e)}>
            <span className={cx('x-1')}></span>
            <span className={cx('x-2')}></span>
          </a>
          <div className={cx('modal__inner')} ref={refInner}>
            {slideList && (
              <div className={cx('modal__slider')} ref={refSlider}>
                <Slider slides={slideList} />
              </div>
            )}
            <div className={cx('modal__content')}>
              <h3 className={cx('modal__content__name')}>{currentItem.name}</h3>
              <p className={cx('modal__content__detail')}>{currentItem.desc}</p>
              <table className={cx('info__list')}>
                <tbody>
                  <tr>
                    <th>Client</th>
                    <td>{currentItem.client}</td>
                  </tr>
                  <tr>
                    <th>Technology</th>
                    <td>{currentItem.tech}</td>
                  </tr>
                  <tr>
                    <th>Product</th>
                    <td>
                      {currentItem.infoUrl ? (
                        <a href={currentItem.infoUrl} rel="noreferrer">
                          View more
                        </a>
                      ) : (
                        'Private'
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Project;
