import React, { useRef, useEffect } from 'react';
import { init } from 'ityped';
import classNames from 'classnames/bind';
import styles from './Typing.module.scss';

const cx = classNames.bind(styles);

const Typing = ({ strings }) => {
  const elmType = useRef();
  useEffect(() => {
    init(elmType.current, {
      showCursor: false,
      strings,
    });
  }, []);

  return <span className={cx('type__text')} ref={elmType}></span>;
};

export default Typing;
