import React from 'react';
import './CardInner.scss';
const CardInner = ({ isActive, title, idName, children }) => {
  return (
    <div className={`card__inner ${isActive ? 'active' : ''}`} id={idName}>
      <div className="card__wrap">
        <div className="content">
          <h2 className="title">
            <span>{title}</span>
          </h2>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardInner;
