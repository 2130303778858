import React from 'react';
import { Zoom } from 'react-slideshow-image';
import styles from './Slider.module.scss';
import 'react-slideshow-image/dist/styles.css';
import './customSlider.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Slider = ({ slides }) => {
  if (slides && slides.length > 1)
    return (
      <Zoom scale={1.4} indicators={true}>
        {slides.map((slide, index) => (
          <div key={index} className={cx('slide_item')}>
            <img alt="Slide Image" src={slide} />
          </div>
        ))}
      </Zoom>
    );
  if (slides && slides.length == 1)
    return (
      <div className={cx('slide_item')}>
        <img src={slides[0]} />
      </div>
    );
};

export default Slider;
