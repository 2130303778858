import React, { useContext, useState } from 'react';
import { HeaderContext } from '../index.js';

const MenuItem = ({ isActive, icon, menuName, menuTarget }) => {
  const [menuActive, setMenuActive] = useState('');
  const { elmTopMenu, elmMenuList, setPositionFx, isMobile, isTablet } = useContext(HeaderContext);

  const menuItemHandle = (e, target) => {
    const itemMenu = [...elmTopMenu.current.querySelectorAll('li')];
    itemMenu.map((item) => item.classList.remove('active'));
    setMenuActive(menuActive === 'active' ? '' : 'active');
    e.currentTarget.parentElement.classList.add('active');
    const cardInner = [...document.querySelectorAll('.card__inner')];
    cardInner.map((item) => item.classList.remove('active'));
    document.getElementById(target.replace('#', '')).classList.add('active');
    document.getElementById(target.replace('#', '')).style.animation = 'innOpen 1s linear 1 alternate';
    setPositionFx(elmMenuList.current);
    if (isTablet) {
      const id = target.replace('#', ''),
        yOffset = -85,
        targetElm = document.getElementById(id),
        y = targetElm.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
      document.getElementById(target.replace('#', '')).style.animation = 'unset';
    }
    if (isMobile) {
      const id = target.replace('#', ''),
        yOffset = -5,
        targetElm = document.getElementById(id),
        y = targetElm.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
      // document.getElementById(target.replace('#', '')).scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      //   inline: 'start',
      // });
      document.getElementById(target.replace('#', '')).style.animation = 'unset';
    }
  };
  return (
    <li className={isActive ? 'active' : ''}>
      <a onClick={(e) => menuItemHandle(e, menuTarget)}>
        <span className={`icon ${icon}`}></span>
        <span className="link">{menuName}</span>
      </a>
    </li>
  );
};

export default MenuItem;
