import React, { useRef, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

const About = () => {
  const refYearold = useRef();
  useEffect(() => {
    refYearold.current.innerText = new Date(new Date() - new Date('1994/10/07')).getFullYear() - 1970;
  });
  return (
    <>
      <table className={cx('info__list')}>
        <tbody>
          <tr>
            <th>Age</th>
            <td>
              <span ref={refYearold}></span>
            </td>
          </tr>
          <tr>
            <th>Address</th>
            <td>Ha Noi, Viet Nam</td>
          </tr>
        </tbody>
      </table>
      <div className={cx('text__box')}>
        <p>
          I have been attracted to and passionate about IT since high school. My experience includes website development
          and system operation, both Front-end with HTML, CSS (SCSS), Javascript, Reactjs, Jquery, Wordpress and
          Back-end with PHP, Wordpress, Nodejs, Typescript, MySQL, MongoDB. I am also familiar with RESTful APIs and
          proficient in the use of tools such as GIT, SVN, Photoshop, and Illustrator.
        </p>
        <p>
          I hope to leverage my coding skills, experience, and understanding of programming languages to become a
          professional Software Engineer who can provide value to customers and companies. I would like to be recognized
          as someone with deep expertise in software development, such as a Full-stack developer or CTO.
        </p>
      </div>
    </>
  );
};

export default About;
