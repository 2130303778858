import React from 'react';
import styles from '../Timeline.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const TimelineItem = ({ period = false, info, company, position }) => {
  return (
    <li className={cx('timeline__item', period && 'period')}>
      {info && (
        <div className={cx('timeline__info')}>
          <span>{info}</span>
        </div>
      )}
      <div className={cx('timeline__marker')}></div>
      <div className={cx('timeline__content')}>
        {company && <h3 className={cx('timeline__title')}>{company}</h3>}
        {position && <p>{position}</p>}
      </div>
    </li>
  );
};

export default TimelineItem;
