import React, { useRef, useState } from 'react';
import styles from './Contact.module.scss';
import classNames from 'classnames/bind';
import * as amplitude from '@amplitude/analytics-browser';

import Sending from '../Sending';

const cx = classNames.bind(styles);
const mailUrl = 'binhnt383@gmail.com';

const Contact = ({ setIndex }) => {
  const formRef = useRef(),
    phoneRef = useRef(),
    scriptUrl =
      'https://script.google.com/macros/s/AKfycbw1oS1itfZwxWVgIVmhmK5nEgxhzR3xSZwgf6c7AMAgct9glsM3uhFplSLGk5ZimJbdIA/exec',
    [phone, setPhone] = useState(),
    [email, setEmail] = useState(),
    [loading, setLoading] = useState(false),
    [isSent, setIsSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!phone && !email) {
      alert('Please leave your information (phone number or Email address) so I can contact you!');
      phoneRef.current.focus();
    } else {
      setIndex(1);
      setLoading(true);
      fetch(scriptUrl, {
        method: 'POST',
        body: new FormData(formRef.current),
      })
        .then((res) => {
          setIndex(2);
          setLoading(false);
          res.ok && setIsSent(true);
          amplitude.track('Sign Up');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <table className={cx('info__list')}>
        <tbody>
          <tr>
            <th>Phone:</th>
            <td><a href='tel:0868838934'>0868.838.xxx</a></td>
          </tr>
          <tr>
            <th>Email:</th>
            <td>
              <a className={cx('contact__lnk')} href={`mailto:${mailUrl}`}>
                {mailUrl}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <form ref={formRef} onSubmit={handleSubmit} name="contact_form">
        <div className={cx('form_group')}>
          <input className={cx('form_group__item')} type="text" name="name" placeholder="Your Name" />
        </div>
        <div className={cx('form_group')}>
          <input
            className={cx('form_group__item')}
            type="number"
            name="phone"
            placeholder="Your Phone"
            onChange={(e) => setPhone(e.target.value)}
            ref={phoneRef}
          />
          <input
            className={cx('form_group__item')}
            type="email"
            name="email"
            placeholder="Your Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={cx('form_group')}>
          <label htmlFor="subject" className={cx('form_group__item', 'form_group__item__label')}>
          Purpose of Contact:{' '}
          </label>
          <select className={cx('form_group__item')} id="subject" name="subject">
            <option value="recruit">Recruit</option>
            <option value="freelance">Job free lance</option>
          </select>
        </div>
        <div className={cx('form_group')}>
          <textarea className={cx('form_group__item')} rows="4" name="content" placeholder="Your message"></textarea>
        </div>
        <div className={cx('form_group')}>
          <input type="submit" value={isSent ? 'SENT' : 'SEND MESSAGE'} />
        </div>
      </form>
      {loading && <Sending />}
    </>
  );
};

export default Contact;
