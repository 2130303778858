import React, { useRef } from 'react';
import styles from '../Project.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ProjectItem = ({ idProject, img, name, modalHandleOpen }) => {
  const refCard = useRef();
  return (
    <div className={cx('card')} ref={refCard} onClick={() => modalHandleOpen(idProject, refCard)}>
      <div className={cx('border')}></div>
      <img src={img} />
      <h3>{name}</h3>
    </div>
  );
};

export default ProjectItem;
