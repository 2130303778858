import React from 'react';
import classNames from 'classnames/bind';
import styles from './Reward.module.scss';

const cx = classNames.bind(styles);

const Reward = () => {
  return (
    <>
      <div className={cx('text__box')}>
        <p>
          <span className={cx('date')}>2021 - 2022</span>
          <br />
          <span className={cx('subject')}>Outstanding Employee of the Year</span>
        </p>
        <p>
          <img src="https://firebasestorage.googleapis.com/v0/b/binhnt-383.appspot.com/o/rewards%2F34f0d962cecb119548da.jpg?alt=media&token=dce4f369-a2eb-413e-97ed-9c8e9a43b0a6" />
        </p>
      </div>
    </>
  );
};

export default Reward;
