import React from 'react';
import profile from '../../images/profile.jpg';
import bg from '../../images/bg.webp';
import Typing from '../Typing';
import styles from './CardStarted.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const CardStarted = ({ indexCard }) => {
  const typingTexts = ['Website developer', 'Freelancer'];
  return (
    <div className={cx('card__started')} style={{ '--index': indexCard }} id="home-card">
      <div className={cx('profile')}>
        <div
          className={cx('slide')}
          style={{
            backgroundImage: `url('${bg}')`,
          }}
        ></div>
        <div className={cx('image')}>
          <img src={profile} alt="" />
        </div>
        <div className={cx('title')}>THANH BÌNH</div>
        <div className={cx('subtitle')}>
          <Typing strings={typingTexts} /> &nbsp;
        </div>
        <div className={cx('social')}>
          <a target="_blank" href="https://facebook.com/" rel="noreferrer">
            <span className="fab fa-facebook"></span>
          </a>
          <a target="_blank" href="https://www.instagram.com/" rel="noreferrer">
            <span className="fab fa-instagram"></span>
          </a>
          <a target="_blank" href="https://twitter.com/" rel="noreferrer">
            <span className="fab fa-twitter"></span>
          </a>
          <a target="_blank" href="https://github.com/" rel="noreferrer">
            <span className="fab fa-github"></span>
          </a>
        </div>
        <div className={cx('btns')}>
          {/* <a href="https://firebasestorage.googleapis.com/v0/b/binhnt-383.appspot.com/o/CV_Nguyen_Thanh_Binh_Website_Developer.pdf?alt=media&token=25586b07-0ed1-4a08-bed4-cecf8059d1fd">
            Download My CV
            <i className="fas fa-file-download"></i>
          </a> */}
          <a href="mailto:binhnt383@gmail.com">
            Email me
            <i class="fal fa-envelope"></i>
          </a>
          {/* <a href="#">
            Contact me<i className="fas fa-paper-plane"></i>
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default CardStarted;
