import React, { useEffect, useState } from 'react';
import styles from './Timeline.module.scss';
import classNames from 'classnames/bind';
import { ref, child, get } from 'firebase/database';

import { database } from '../../firebase';
import TimelineItem from './components/TimelineItem';

const cx = classNames.bind(styles);

const Timeline = () => {
  const dbRef = ref(database),
    [exp, setExp] = useState([]);
  useEffect(() => {
    get(child(dbRef, `Experience`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setExp(snapshot.val());
        } else {
          console.log('No data available');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <ul className={cx('timeline')}>
      {exp.map((item, index) => (
        <TimelineItem
          key={index}
          period={item.period}
          info={item.info}
          company={item.company}
          position={item.position}
        />
      ))}
    </ul>
  );
};

export default Timeline;
